import * as React from "react";
import styles from "../styles/pages/home.module.scss";
import MetaHeader from "../components/meta-header/meta-header";
import { trackPage } from "../api";
// import { ProductComponent } from "./sale";
// import LazyProductImage from "../components/lazy-product-image/lazy-product-image";
import { HeaderNewComponent } from "../new-components/header/Header";
import FooterNewComponent from "../new-components/footer/Footer";
import PhoneNewComponent from "../new-components/home/Phone/Phone";
import ValuesNewComponent from "../new-components/home/Values/Values";
import KeysNewComponent from "../new-components/home/Keys/Keys";
import AvisNewComponent from "../new-components/home/Avis/Avis";
import InstagramNewComponent from "../new-components/home/Instagram/Instagram";
import { useTranslation } from "next-i18next";
import { canonical } from "../config";
import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import nextI18NextConfig from "../next-i18next.config.js";

const HomeScreen = (props) => {
  const { t, i18n } = useTranslation([
    "common",
    "phone",
    "values",
    "keys",
    "instagram",
    "avis",
  ]);

  function getDescription() {
    return t("description");
  }

  function getTitle() {
    return "Choose | Your daily drop of cool.";
  }

  React.useEffect(() => {
    trackPage("Home", {
      store: props.store,
      lang: props.lang,
      language: props.language,
      i18N: i18n.language,
    });
  }, [i18n.language, props.lang, props.language, props.store]);

  function setSchemaFAQ() {
    return {
      __html: `{"@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Quelle est la politique de retour ?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"Vous pouvez décider de retourner votre colis jusqu’à 14 jours.",
      "url":"https://appchoose.io/returns"
    }
  }, {
    "@type": "Question",
    "name": "Quels sont les délais de livraison ?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text":"Les délais et les frais de livraison diffèrent selon les ventes mais nous nous efforçons de les réduire au maximum.
",
      "url":"https://appchoose.io/shipping"
    }
  }]
  }`.replace(/\n/g, ""),
    };
  }
  const language = i18n.language;
  return (
    <div className="home-page">
      <MetaHeader
        description={getDescription()}
        title={getTitle()}
        canonical={canonical}
      />

      <div className={styles.container}>
        <HeaderNewComponent store={props.store} language={language} />
        <PhoneNewComponent store={props.store} />
        <div className={`${styles.color} col-sm-0`} />
      </div>
      <ValuesNewComponent store={props.store} />
      <AvisNewComponent />
      <KeysNewComponent />
      <InstagramNewComponent store={props.store} />
      <FooterNewComponent />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={setSchemaFAQ()}
      />
    </div>
  );
};

export const getServerSideProps = async (props) => {
  return {
    props: {
      ...(await serverSideTranslations(
        props.query.language ||
          props.query.lang ||
          props.locale ||
          props.defaultLocale ||
          "fr",
        ["common", "phone", "values", "keys", "instagram", "avis"],
        nextI18NextConfig as any
      )),
      ...props.query,
    },
  };
};

export default HomeScreen;
