import * as React from "react";
import styles from "./Avis.module.scss";
import LazyProductImage from "../../../components/lazy-product-image/lazy-product-image";
import { useTranslation } from "next-i18next";
import { Carousel } from "react-responsive-carousel";
import { getUserAgent } from "../../../utils/agent";
import { IUserAgent } from "../../../types/ua";
const rates = [
  {
    id: "01",
    message: "chloe",
    order: "6",
    name: "Chloé A.",
  },
  {
    id: "02",
    message: "sixtine",
    order: "12",
    name: "Sixtine B.",
  },
  {
    id: "03",
    message: "julie",
    name: "Julie L.",
    order: "10",
  },
];

function AvisNewComponent() {
  const { t } = useTranslation("avis");
  const [percentage, setPercentage] = React.useState(85);
  const [arrows, setArrows] = React.useState(false);

  const [userAgent, setUserAgent] = React.useState<IUserAgent>(
    {} as IUserAgent
  );
  React.useEffect(() => {
    const ua = getUserAgent();
    setUserAgent(ua);
    if (window.innerWidth >= 768) {
      setPercentage(60);
      setArrows(true);
    }
  }, []);
  return (
    <div className={`${styles.avis} container align-items-center`}>
      <h2 dangerouslySetInnerHTML={{ __html: t("title") }} />

      {userAgent.isMobile ? (
        <Carousel
          className={styles.carousel}
          infiniteLoop={false}
          centerMode={true}
          centerSlidePercentage={percentage}
          showArrows={arrows}
          showStatus={false}
          autoPlay={false}
          showThumbs={false}
          swipeable={true}
        >
          {rates.map((rate) => {
            return (
              <div key={rate.id} className={styles.content}>
                <LazyProductImage
                  height="24"
                  width="124"
                  alt="rate-5"
                  src="https://public.choose.app/website/new/images/star-5.png"
                  srcSet={`https://public.choose.app/website/new/images/star-5@2x.png 2x,https://public.choose.app/website/new/images/star-5@3x.png 3x`}
                />
                <h4>{t(rate.message + "_title")}</h4>
                <p className={styles.text}>{t(rate.message)}</p>
                <footer className={styles.user}>
                  <LazyProductImage
                    height="56"
                    width="56"
                    alt="01"
                    src={`https://public.choose.app/website/2023/${rate.id}.png`}
                    srcSet={`https://public.choose.app/website/2023/${rate.id}@2x.png 2x,https://public.choose.app/website/2023/${rate.id}@3x.png 3x`}
                  />
                  <div style={{ marginLeft: 16 }}>
                    <h5>{rate.name}</h5>
                    <p>
                      {rate.order} {t("orders")}
                    </p>
                  </div>
                </footer>
              </div>
            );
          })}
        </Carousel>
      ) : (
        <ul className="row">
          {rates.map((rate) => {
            return (
              <li key={rate.id} className="col-4 col-sm-12">
                <div className={styles.content}>
                  <LazyProductImage
                    height="24"
                    width="124"
                    alt="rate-5"
                    src="https://public.choose.app/website/new/images/star-5.png"
                    srcSet={`https://public.choose.app/website/new/images/star-5@2x.png 2x,https://public.choose.app/website/new/images/star-5@3x.png 3x`}
                  />
                  <h4>{t(rate.message + "_title")}</h4>
                  <p className={styles.text}>{t(rate.message)}</p>
                  <footer className={styles.user}>
                    <LazyProductImage
                      height="56"
                      width="56"
                      alt="01"
                      src={`https://public.choose.app/website/new/images/avatars/${rate.id}.png`}
                      srcSet={`https://public.choose.app/website/new/images/avatars/${rate.id}@2x.png 2x,https://public.choose.app/website/new/images/avatars/${rate.id}@3x.png 3x`}
                    />
                    <div style={{ marginLeft: 16 }}>
                      <h5>{rate.name}</h5>
                      <p>
                        {rate.order} {t("orders")}
                      </p>
                    </div>
                  </footer>
                </div>
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default AvisNewComponent;
