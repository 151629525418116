// @ts-nocheck
import * as React from "react";
import { InView } from "react-intersection-observer";
import { getUserAgent } from "utils/agent";

interface ILazyResponsiveImage {
  srcSets?: string[];
  src: string;
  sizes?: string[];

  className?: string;
  height?: string;
  style?: any;
  width?: string;
  alt?: string;
  disableWebp?: boolean;
  disableAnim?: boolean;
  containerClassName?: string;
}

export default function LazyResponsiveImage(props: ILazyResponsiveImage) {
  function getImageExtension(src?: string) {
    if (src) {
      return src.replace(/.jpg/g, ".webp").replace(/.png/g, ".webp");
    }
    return src;
  }

  function renderSource(index: number) {
    const media = props.sizes[index]
      ? `(max-width: ${props.sizes[index]})`
      : "";
    return (
      <>
        <source media={media} srcSet={props.srcSets[index]} />
        {!props.disableWebp && (
          <source
            type="image/webp"
            media={media}
            srcSet={getImageExtension(props.srcSets[index])}
          />
        )}
      </>
    );
  }

  function renderImage() {
    return (
      <picture>
        {props.srcSets && props.srcSets.map((_, idx) => renderSource(idx))}
        <img
          src={props.src}
          alt={props.alt}
          width={props.width || null}
          height={props.height || null}
          className={
            (props.className || "") +
            (!props.disableAnim ? " animated fadeIn" : null)
          }
        />
      </picture>
    );
  }

  // const supportsNativeLoading = useNativeLazyLoading()
  // console.log(supportsNativeLoading)
  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <div
          className={props.containerClassName}
          style={{
            height: +props.height || null,
            width: +props.width || null,
            ...props.style,
          }}
          ref={ref}
        >
          {inView ? renderImage() : null}
          {props.children}
        </div>
      )}
    </InView>
  );
}
