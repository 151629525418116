// @ts-nocheck
import * as React from "react";
import { InView } from "react-intersection-observer";
import { getUserAgent } from "utils/agent";
import { VideoComponent } from "./video";
import styles from "./video.module.scss";

interface ILazyProductVideo {
  src: string;
  poster?: string;
  className?: string;
}

export default function LazyProductVideo(props: ILazyProductVideo) {
  return (
    <InView triggerOnce={true}>
      {({ inView, ref }) => (
        <div style={{ position: "relative" }} ref={ref}>
          {inView ? <VideoComponent {...props} /> : null}
          {/* <img alt="pause"
                    height="64 " width="64"
                    className={styles.pause} src="https://public.choose.app/website/new/images/Pause.png"
                    srcSet={`https://public.choose.app/website/new/images/Pause@2x.png 2x,https://public.choose.app/website/new/images/Pause@3x.png 3x`}
                /> */}
        </div>
      )}
    </InView>
  );
}
