import * as React from "react";
import { getUserAgent } from "../../utils/agent";
export function VideoComponent(props: {
  src: string;
  poster?: string;
  className?: string;
}) {
  const videoRef = React.useRef(null);
  React.useEffect(() => {
    const ua = getUserAgent();
    setTimeout(() => {
      if (videoRef.current && !ua.isMobile) {
        try {
          videoRef.current.play();
        } catch (error) {
          //
        }
      }
    }, 3000);
  }, []);

  return (
    <video
      ref={videoRef}
      // controls={userAgent.isMobile}
      playsInline={true}
      // controls={true}
      webkit-playsinline="true"
      autoPlay={true}
      loop={true}
      muted={true}
      poster={props.poster}
      className={props.className}
    >
      <source src={props.src} type="video/mp4" />
      <p>
        Votre navigateur ne prend pas en charge les vidéos HTML5. Voici{" "}
        <a href={props.src}>un lien pour télécharger la vidéo</a>.
      </p>
    </video>
  );
}
