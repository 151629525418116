import * as React from "react";
import styles from "./Instagram.module.scss";

import LazyProductImage from "../../../components/lazy-product-image/lazy-product-image";
import { useTranslation } from "next-i18next";
function InstagramNewComponent(props: { store: string }) {
  const { t } = useTranslation("instagram");
  function onClick() {
    window.open(
      props.store === "us"
        ? "https://www.instagram.com/choose_app_us/"
        : "https://instagram.com/choose_app",
      "_blank"
    );
  }
  return (
    <section className="container align-items-center">
      <div className={`${styles.instagram}  row`}>
        <div className="col-sm-0 col-1 col-md-1" />
        <div className="col-sm-12 col-5">
          <LazyProductImage
            height="148"
            width="148"
            alt="Instagram"
            src="https://public.choose.app/website/2023/InstagramLogo.png"
            srcSet={`https://public.choose.app/website/2023/InstagramLogo@2x.png 2x,https://public.choose.app/website/2023/InstagramLogo@3x.png 3x`}
          />
          <h3 dangerouslySetInnerHTML={{ __html: t("title") }} />
          <button className="black" onClick={onClick}>
            {t("btn")}
          </button>
        </div>
        <a
          href={
            props.store === "us"
              ? "https://www.instagram.com/choose_app_us/"
              : "https://instagram.com/choose_app"
          }
          rel="noreferrer"
          target="_blank"
        >
          <LazyProductImage
            alt="images"
            height="512"
            width="628"
            src="https://public.choose.app/website/2023/InstagramPictures.png"
            srcSet={`https://public.choose.app/website/2023/InstagramPictures@2x.png 2x,https://public.choose.app/website/2023/InstagramPictures@3x.png 3x`}
          />
        </a>
      </div>
    </section>
  );
}

export default InstagramNewComponent;
