import * as React from "react";
import styles from "./Keys.module.scss";
import LazyProductImage from "../../../components/lazy-product-image/lazy-product-image";
import { useTranslation } from "next-i18next";

function KeysNewComponent() {
  const { t } = useTranslation("keys");
  return (
    <div className="container align-items-center">
      <ul className={`${styles.keys} row`}>
        <li className="col-sm-6 col-3 col-md-3">
          <LazyProductImage
            height="104"
            width="104"
            alt="delivery"
            srcSet={`https://public.choose.app/website/2023/Delivery@2x.png 2x,https://public.choose.app/website/2023/Delivery@3x.png 3x`}
            src="https://public.choose.app/website/2023/Delivery.png"
          />

          <p>{t("delivery")}</p>
        </li>
        <li className="col-sm-6 col-3 col-md-3">
          <LazyProductImage
            height="104"
            width="104"
            alt="Notifications"
            srcSet={`https://public.choose.app/website/2023/Notifications@2x.png 2x,https://public.choose.app/website/2023/Notifications@3x.png 3x`}
            src="https://public.choose.app/website/2023/Notifications.png"
          />
          <p>{t("retour")}</p>
        </li>
        <li className="col-sm-6 col-3 col-md-3">
          <LazyProductImage
            height="104"
            width="104"
            alt="Notifications"
            srcSet={`https://public.choose.app/website/2023/Paiement@2x.png 2x,https://public.choose.app/website/2023/Paiement@3x.png 3x`}
            src="https://public.choose.app/website/2023/Paiement.png"
          />
          <p>{t("payment")}</p>
        </li>
        <li className="col-sm-6 col-3 col-md-3">
          <LazyProductImage
            height="104"
            width="104"
            alt="Notifications"
            srcSet={`https://public.choose.app/website/2023/ServiceClient@2x.png 2x,https://public.choose.app/website/2023/ServiceClient@3x.png 3x`}
            src="https://public.choose.app/website/2023/ServiceClient.png"
          />
          <p>{t("support")}</p>
        </li>
      </ul>
    </div>
  );
}

export default KeysNewComponent;
