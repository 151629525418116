import * as React from "react";
import styles from "./Values.module.scss";
import { useTranslation } from "next-i18next";
import { getUserAgent } from "../../../utils/agent";
import LazyResponsiveImage from "components/lazy-responsive-image/lazy-responsive-image";
import { IUserAgent } from "../../../types/ua";

const badgesFR = [
  {
    class: styles.made,
    text: "Made in France",
    brand: "Jolie Roger",
    product: "Veste Courte - Bleu",
    image:
      "https://public.choose.app/website/new/images/badges/Made%20in%20France/img-joli-roger.jpg",
  },
  {
    class: styles.premium,
    text: "Premium",
    brand: "IKA Paris",
    product: "Carré Mousseline De Soie - Leo",
    image:
      "https://public.choose.app/website/new/images/badges/Premium/img-ika.jpg",
  },
  {
    class: styles.upcycling,
    text: "Upcycling",
    brand: "Salut Beauté",
    product: "Veste Bleu Nuit - The Cosmic Uniform",
    image:
      "https://public.choose.app/website/new/images/badges/Upcycling/img-salut-beaute.jpg",
  },
  {
    class: styles.handmade,
    text: "Fait Main",
    brand: "Gisel B",
    product: "Collier Ester avec Perles",
    image:
      "https://public.choose.app/website/new/images/badges/Hand%20Made/img-gisel-b.jpg",
  },
  {
    class: styles.natural,
    text: "Naturel",
    brand: "By Mukk",
    product: "Soin Anti-Imperfection",
    image:
      "https://public.choose.app/website/new/images/badges/Naturel/img-by-mukk.jpg",
  },
  {
    class: styles.gift,
    text: "Cadeau parfait",
    brand: "Transparent Sound",
    product: "Petite Enceinte Transparente",
    image:
      "https://public.choose.app/website/new/images/badges/Perfect%20Gift/img-transparent-sound.jpg",
  },
  {
    class: styles.bio,
    text: "Bio",
    brand: "Jolies Culottes",
    product: "Bloomer - Vichy Marine",
    image:
      "https://public.choose.app/website/new/images/badges/Bio/img-jolies-culottes.jpg",
  },
];

const badgeUS = [
  {
    class: styles.made,
    text: "European brands",
    brand: "Jolie Roger",
    product: "Veste Courte - Bleu",
    image:
      "https://public.choose.app/website/new/images/badges/Made%20in%20France/img-joli-roger.jpg",
  },
  {
    class: styles.premium,
    text: "Premium",
    brand: "IKA Paris",
    product: "Carré Mousseline De Soie - Leo",
    image:
      "https://public.choose.app/website/new/images/badges/Premium/img-ika.jpg",
  },
  {
    class: styles.substainable,
    text: "Sustainable",
    brand: "Salut Beauté",
    product: "Veste Bleu Nuit - The Cosmic Uniform",
    image:
      "https://public.choose.app/website/new/images/badges/Upcycling/img-salut-beaute.jpg",
  },
  {
    class: styles.handmade,
    text: "Fait Main",
    brand: "Gisel B",
    product: "Collier Ester avec Perles",
    image:
      "https://public.choose.app/website/new/images/badges/Hand%20Made/img-gisel-b.jpg",
  },
  {
    class: styles.women,
    text: "Women-owned",
    brand: "By Mukk",
    product: "Soin Anti-Imperfection",
    image:
      "https://public.choose.app/website/new/images/badges/Naturel/img-by-mukk.jpg",
  },
  {
    class: styles.gift,
    text: "Cadeau parfait",
    brand: "Transparent Sound",
    product: "Petite Enceinte Transparente",
    image:
      "https://public.choose.app/website/new/images/badges/Perfect%20Gift/img-transparent-sound.jpg",
  },
  {
    class: styles.exclusive,
    text: "Exclusive",
    brand: "Jolies Culottes",
    product: "Bloomer - Vichy Marine",
    image:
      "https://public.choose.app/website/new/images/badges/Bio/img-jolies-culottes.jpg",
  },
];

function ValuesNewComponent(props: { store: string }) {
  const { t, i18n } = useTranslation("values");
  const [displayIndex, setDisplayIndex] = React.useState(-1);
  const [userAgent, setUserAgent] = React.useState<IUserAgent>(
    {} as IUserAgent
  );
  React.useEffect(() => {
    const ua = getUserAgent();
    setUserAgent(ua);
  }, []);

  function onMouseEnter(event: any) {
    if (!userAgent.isMobile) {
      const idx = event.target.getAttribute("data-idx");
      if (typeof idx === "string" && +idx !== displayIndex) {
        setDisplayIndex(+idx);
      }
    }
  }

  function onMouseLeave() {
    if (!userAgent.isMobile) {
      if (typeof displayIndex === "number" && displayIndex > -1) {
        const elt = document.getElementById(
          "image-container-anim" + displayIndex
        );
        elt.classList.add("animated", "fadeOut", styles.fadeOut);
        const elts = document.querySelectorAll("li." + styles.hover);
        elts.forEach((li) => {
          li.classList.add(styles.fadeOut);
        });
        setTimeout(() => {
          setDisplayIndex(-1);
          elt.classList.remove("animated", "fadeOut", styles.fadeOut);
          elts.forEach((li) => {
            li.classList.remove(styles.fadeOut);
          });
        }, 250);
      } else {
        setDisplayIndex(-1);
      }
    }
  }
  const badges =
    i18n.language === "en" || props.store === "us" ? badgeUS : badgesFR;
  return (
    <div className={styles.values}>
      <section className="container align-items-center">
        <div className="row">
          <div className="col-1 col-sm-0 col-md-1" />
          <div className={`col-sm-12 col-5 col-md-5 ${styles.content}`}>
            <h2 dangerouslySetInnerHTML={{ __html: t("title") }} />
            <p className={styles.subtext}>
              {t("text1")}
              <br />
              <br />
              {t("text2")}
            </p>
            <LazyResponsiveImage
              containerClassName={styles.choose_future}
              alt="Choosethefuture"
              src="https://public.choose.app/website/2023/ChooseTheFuture.png"
              srcSets={[
                `https://public.choose.app/website/new/images/ChooseTheFuture-small.png 1x, https://public.choose.app/website/new/images/ChooseTheFuture-small@2x.png 2x,https://public.choose.app/website/new/images/ChooseTheFuture-small@3x.png 3x`,
                `https://public.choose.app/website/2023/ChooseTheFuture.png 1x, https://public.choose.app/website/2023/ChooseTheFuture@2x.png 2x,https://public.choose.app/website/2023/ChooseTheFuture@3x.png 3x`,
              ]}
              sizes={["960px"]}
            />
            {badges.map((badge, idx) => {
              return displayIndex === idx ? (
                <div
                  key={badge.text}
                  id={`image-container-anim${idx}`}
                  className={`${styles.hover}`}
                  style={{ zIndex: 1000 }}
                >
                  <div className={styles.anim}>
                    <LazyResponsiveImage
                      containerClassName={styles.value_image}
                      disableAnim={true}
                      src={badge.image}
                    />
                    {/* <img src={badges[displayIndex].image} /> */}
                    <p className={styles.brand}>{badge.brand}</p>
                    <p className={styles.description}>{badge.product}</p>
                  </div>
                </div>
              ) : null;
            })}
          </div>
          <div className="col-1 col-sm-0 col-md-0" />
          <div className={`${styles.badges} col-sm-12 col-4 col-md-5`}>
            <ul onMouseOver={onMouseEnter} onMouseLeave={onMouseLeave}>
              {badges.map((badge, idx) => (
                <li
                  data-idx={idx}
                  className={idx === displayIndex ? styles.hover : undefined}
                  key={idx}
                >
                  <div className={styles.licontainer} data-idx={idx}>
                    <div
                      data-idx={idx}
                      className={`${styles.stripe} ${badge.class}`}
                    />
                    <p data-idx={idx}>{t(badge.text)}</p>
                    <hr />
                  </div>
                </li>
              ))}
            </ul>
            <p>{t("more")}</p>
            {/* <div className={`${styles.more} col-sm-0`}>
                    <LazyProductImage
                        height="70" width="88"
                        alt="ArrowDown" src="https://public.choose.app/website/new/images/HoverMe.svg" />
                    <p>{t('hover')}</p>
                </div> */}
          </div>
        </div>
      </section>
    </div>
  );
}

export default ValuesNewComponent;
